<template>
  <div class="relative h-[25rem] shadow-2xl">
    <div class="absolute top-0 left-0 w-1/2 h-1/2 bg-orange"></div>
    <div class="absolute top-0 right-0 w-1/2 h-1/2 bg-orange-light"></div>
    <div ref="boxRef" class="relative top-1/3 text-center z-10">
      <h1 class="text-6xl font-bold text-black">
        PRASHANT <span class="text-orange">INNOVATIO</span>
      </h1>
      <p ref="textRef" class="text-xl text-grey mt-4">
        "INNOVATE. IMPLEMENT. IMPACT."
      </p>
    </div>
  </div>
</template>

<script setup>
import { gsap } from "gsap";
import { onMounted, ref } from "vue";

const boxRef = ref(null);
const textRef = ref(null);

onMounted(() => {
  gsap.fromTo(
    boxRef.value,
    { opacity: 0 },
    { opacity: 1, duration: 1, ease: "power1.inOut" }
  );

  gsap.fromTo(
    textRef.value,
    { opacity: 0, y: -50 },
    {
      opacity: 1,
      y: 0,
      duration: 2,
      ease: "power1.inOut",
      delay: 0.5,
    }
  );
});
</script>

<style scoped></style>
