<template>
  <div class="py-16 px-8 bg-white text-black shadow-2xl">
    <h2 class="text-4xl font-semibold text-black">Our Services</h2>
    <div class="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8">
      <div
        v-for="service in serviceData"
        :key="service.title"
        class="service-item">
        <h3 class="text-xl font-bold text-orange text-center">
          {{ service.title }}
        </h3>
        <lottie-animation :animationData="service.animationData" />
        <p class="text-grey-dark">{{ service.description }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import LottieAnimation from "./LottieAnimation.vue";
const serviceData = [
  {
    title: "Idea Consultation",
    animationData:
      "https://lottie.host/5a7678fe-fe61-4e5d-a2a5-18b7160d28d2/PKjFF4O0po.json",
    description:
      "Transform your concepts into actionable plans with our Idea Consultation services. We provide expert guidance on refining your ideas, validating their potential, and shaping them into scalable, sustainable solutions. Whether you're in the early stages of ideation or looking to fine-tune a concept, we help you navigate the path from idea to execution.",
  },
  {
    title: "Product Design and Development",
    animationData:
      "https://lottie.host/b19694ed-aa60-4651-b15a-56da17974b57/fZdfb2YiD4.json",
    description:
      "Elevate your business with our comprehensive product design and development services. We specialize in creating both digital and physical solutions, combining cutting-edge UI/UX design, thorough research, and innovative development strategies. Whether you're launching a new product or optimizing an existing one, we ensure every design is functional, user-friendly, and aligned with your business objectives.",
  },
  {
    title: "Architectural Design and Technical Solutions",
    animationData:
      "https://lottie.host/b2155bcd-b38a-4858-aae2-f62b653cade4/qaV4xORL4A.json",
    description:
      "Transform your spaces with our expert Architectural Design and Technical Solutions. We offer detailed planning and creative interior designs, integrating innovative solutions to craft harmonious, functional environments. Whether designing a commercial building or a private space, we provide comprehensive strategies that blend aesthetics with technical precision.",
  },
  {
    title: "Solar Consultation and Solutions",
    animationData:
      "https://lottie.host/6135cc28-de3d-4990-b50e-bc35ef697a3a/1AnNG4gBH0.json",
    description:
      "Harness the power of the sun with our Solar Consultation and Solutions. We provide expert advice on optimizing energy efficiency, helping you implement sustainable solar strategies that reduce costs and minimize environmental impact. From system design to implementation, we guide you every step of the way toward a greener future.",
  },
  {
    title: "Reverse Engineering",
    animationData:
      "https://lottie.host/7121eaec-0f50-45fd-90bf-cbd8f658e897/crN8btfrPy.json",
    description:
      "Give your products a new life with our Reverse Engineering services. We specialize in analyzing and reconstructing existing designs to optimize performance, enhance functionality, and improve manufacturing processes. Whether it's improving a product's design or troubleshooting production issues, our team delivers precise, actionable insights.",
  },
  {
    title: "Geotechnical Engineering",
    animationData:
      "https://lottie.host/755ef45c-eef2-4516-b83a-859d7cb1dd15/qYH3VWO4y8.json",
    description:
      "Ensure the safety and stability of your projects with our Geotechnical Engineering services. We provide in-depth soil and foundation analysis, offering expert solutions to address geotechnical challenges. Our services help mitigate risks and guarantee the long-term durability of your construction projects.",
  },
];
</script>

<style lang="scss" scoped>
.service-item {
  display: grid;
  grid-template-rows: auto minmax(300px, 25vw) 1fr;
  gap: 8px;
}
</style>
